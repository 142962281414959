<template>
  <div class="content">
    <div class="contentbox">
      <div class="SearchBar">
        <div style="display: flex">
          <span style="margin: 6px 10px 0 10px ">工程编号/名称:</span>
          <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.projectStr"></el-input>
          <span style="margin: 6px 10px 0 10px ">现场管理员：</span>
          <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.siteManager"></el-input>
          <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
          <el-button type="primary" class="SearchBtn" size="small" @click="exportFile" style="margin-left: 10px">导出</el-button>
        </div>

      </div>
      <!--数据表格-->
      <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%;margin: 15px 0;"
          border
          height="calc(100vh - 339px)"
          :stripe="true">
        <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
        <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="teamName" label="安装施工队" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="applyCount" label="申请次数" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unapproveCount" label="驳回次数" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeSum" label="其他费用合计(元)" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="工程创建时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="{row}">
            <el-button  @click="showDetil(row)" type="primary" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination
            :current-page="searchBox.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="searchBox.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="明细"
        :visible.sync="detildialogVisible"
        :close-on-click-modal="false"
        width="80%">
      <el-button type="primary" @click="exportDetil">导出</el-button>
      <el-table
          :data="detiltableData"
          style="width: 100%;margin: 15px 0;"
          border
           height="500"
          :stripe="true">
        <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
        <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="teamName" label="安装施工队" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApplyImage" label="现场图片" align="center">
          <template slot-scope="{row}">
            <el-image
                    style="width: 100px; height: 100px"
                    :src="row.feeApplyImage.split(',')[0]"
                    :preview-src-list="row.feeApplyImage.split(',')">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="feeApplyDescription" label="费用描述" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApplyRealname" label="申请人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApplyTime" label="申请时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApproveResult" label="审批结果" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApproveDescription" label="审批描述" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApproveRealname" label="审批人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeApproveTime" label="审批时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="feeRegisterAmount" label="本次费用(元)" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import {searchExtraFeeList,searchExtraFeeDetail,exportExtraFeeDetail,exportExtraFeeList} from '../../RequestPort/cost/cost'
  import { exportMethod } from '../../common/js/exportExcel'
export default {
  name: "other",
  data(){
    return{
      detildialogVisible:false,
      searchBox:{
        current:1,
        size: 100,

      },
      total:null,
      projectId:null,
      projectName:null,
      tableData:[],
      detiltableData:[]
    }
  },
  mounted(){
    this.loadList(this.searchBox)
  },
  methods:{
    search(){
      this.searchBox.current = 1
      this.loadList(this.searchBox)
    },
    exportFile(){
      exportExtraFeeList(this.searchBox).then(res=>{
        console.log(res)
        exportMethod(res, '其他费用信息')
      })
    },
    exportDetil(){
      exportExtraFeeDetail({projectId:this.projectId,projectName:this.projectName}).then(res=>{
        exportMethod(res, this.projectName+'其他费用信息')
      })
    },
    loadList(obj){
      searchExtraFeeList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    showDetil(row){
      this.detildialogVisible = true
      this.projectId = row.projectId
      this.projectName = row.projectName
      searchExtraFeeDetail({projectId:row.projectId}).then(res=>{
        this.detiltableData = res.data
      })
    },

    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList(this.searchBox)
    },
  }
}
</script>

<style scoped>
.SearchBar {
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.content{
  width: 100%;
  background: #f3f4f8;
}
.contentbox {
  margin: 10px;
  padding: 10px;
  background-color:#fff;
  position: relative;
}
</style>